<template>
    <svg 
     width="15px" 
     height="18px"
     class="profileAge"
     viewBox="0 0 15 18" 
     version="1.1" 
     xmlns="http://www.w3.org/2000/svg" 
     xmlns:xlink="http://www.w3.org/1999/xlink">
    
    <!-- Generator: sketchtool 63.1 (101010) - https://sketch.com -->
    <title>BA5F7F22-6FC1-44CD-B1D8-78E8FFD62B7A</title>
    <desc>Created with sketchtool.</desc>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
     <g 
     id="Spec" 
     transform="translate(-959.000000, -648.000000)" 
     fill="#2A2B75" 
     fill-rule="nonzero">
    <g 
      id="Calender" 
      transform="translate(958.500000, 647.828246)">
      <path d="M14,2.25 L13.25,2.25 L13.25,0.75 L11.75,0.75 L11.75,2.25 L4.25,2.25 L4.25,0.75 L2.75,0.75 L2.75,2.25 L2,2.25 C1.175,2.25 0.5,2.925 0.5,3.75 L0.5,15.75 C0.5,16.575 1.175,17.25 2,17.25 L14,17.25 C14.825,17.25 15.5,16.575 15.5,15.75 L15.5,3.75 C15.5,2.925 14.825,2.25 14,2.25 Z M14,15.75 L2,15.75 L2,7.5 L14,7.5 L14,15.75 Z M14,6 L2,6 L2,3.75 L2.75,3.75 L4.25,3.75 L11.75,3.75 L13.25,3.75 L14,3.75 L14,6 Z" 
      id="Shape" stroke="#2A2B75" class="profileAgeSvg" ></path>
    </g>
     </g>
    </g>
    </svg>
    </template>
    
    <script>
    export default{
      name:"calenderSvg",
    }
    </script>
    <style lang="scss">
    @import "@/sass/_variables.scss";
    @import "@/sass/_components.scss";
    
    .profileAge{
      margin:3px;
    }
    .profileAgeSvg{
      fill:$clr-profileAgesvg;
      stroke: $clr-profileAgesvg;
    }
    </style>